.feehead-table {
	border: 1px solid black;
	border-collapse: collapse;
	width: 100%;
	margin: 30px 0px;
}

.feehead-table td,
th {
	border: 1px solid black;
	padding: 8px;
	text-align: center;
}

/* .feehead-table tr:nth-child(even) {
	background-color: #dddddd;
} */

.feehead-table thead {
	background-color: #dddddd;
}

.feehead-table tfoot {
	background-color: #dddddd;
}
